import { actions as ProjectPageActions } from '../actions/changeProjectPageLayout';

const initialState = {
  isCardView: false,
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case ProjectPageActions.CHANGE_PROJECT_PAGE_LAYOUT:
      return {
        ...state,
        isCardView: action.payload.isCardView
      }
    default:
      return state
  }
}
