import { Responsive } from 'semantic-ui-react';

export const getWidth = (): number => {
  const isSSR = typeof window === 'undefined'
  return isSSR ? Responsive.onlyTablet.minWidth ? parseInt(Responsive.onlyTablet.minWidth.toString()) : 720 : window.innerWidth;
}

// https://stackoverflow.com/a/8649003/2116184
export const getLocationQueryObject = (): { [key: string]: string } | null => {
  if (window.location && window.location.search) {
    let search = window.location.search.substring(1);
    try {
      return JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) });
    } catch (e) {
      return null;
    }
  }
  return null;
}