import React, { Component } from "react";
import { Responsive } from "semantic-ui-react";
import Navbar from "../Navbar";
import MobileSideNavbar from "../MobileSideNavbar";
import { getWidth } from "../../utils";

export interface AppShellProps {
  navbarTitle?: string;
}
class AppShell extends Component<AppShellProps> {
  render() {
    const { children } = this.props;
    return (
      <div>
        {/* DesktopContainer */}
        <Responsive
          getWidth={getWidth}
          minWidth={Responsive.onlyTablet.minWidth}
        >
          <Navbar />
          <div style={{ paddingTop: "2em" }}>{children}</div>
          <br />
        </Responsive>

        {/* MobileContainer */}
        <Responsive
          getWidth={getWidth}
          maxWidth={Responsive.onlyMobile.maxWidth}
        >
          <MobileSideNavbar navbarTitle={this.props.navbarTitle}>
            <div style={{ paddingTop: "2em" }}>{children}</div>
          </MobileSideNavbar>
        </Responsive>
      </div>
    );
  }
}

export default AppShell;
