import React, { Component } from 'react';
import { Menu, Segment, Grid } from 'semantic-ui-react';
import { NavLink } from "react-router-dom";
import styles from './navbar.module.css';

export const NAVBAR_ITEMS = {
  home: 'home',
  blog: 'blog',
  work: 'work',
  projects: 'projects',
  resume: 'resume',
  about: 'about',
  contact: 'contact',
}

interface NavProps {
  location: any,
  to: string
}
class Nav extends Component<NavProps> {
  render() {
    return (
      <NavLink
        to={this.props.to}
        {...this.props}
        isActive={(match: any, location: any): boolean => {
          if (match) {
            const thisMatch = match.path.substring(1, match.length);
            if (thisMatch === '/') {
              if (location.pathname === '/') {
                return true;
              }
            } else {
              return (location.pathname as string).indexOf(thisMatch) >= 0;
            }

          }
          return false;
        }}
      />
    );
  }
}

class Navbar extends Component {
  render() {
    return (
      <Segment inverted className={styles.navbar}>
        <Grid centered columns={16}>
          <Menu inverted pointing secondary>
            <Menu.Item as={Nav} to='/'
              name='home'
            />
            <Menu.Item as={Nav} to='/blog'
              name='blog'
            />
            {/* <Menu.Item as={Nav} to='/work'
              name='work'
            /> */}
            <Menu.Item as={Nav} to='/projects'
              name='projects'
            />
            <Menu.Item as={Nav} to='/resume'
              name='resume'
            />
            {/* <Menu.Item as={Nav} to='/about'
              name='about'
            /> */}
            <Menu.Item as={Nav} to='/contact'
              name='contact'
            />

          </Menu>

        </Grid>

      </Segment>
    );
  }
}

export default Navbar;
