
export const actions = {
  CHANGE_PROJECT_PAGE_LAYOUT: 'CHANGE_PROJECT_PAGE_LAYOUT'
}

export const changeProjectPageLayout = (isCardView: boolean) => (dispatch: Function) => {
  dispatch({
    type: 'CHANGE_PROJECT_PAGE_LAYOUT',
    payload: { isCardView }
  })
}