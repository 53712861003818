import React, { Component } from 'react';
import ReactGA from 'react-ga';
import AppShell, { AppShellProps } from './components/AppShell/index';
import { Route, Switch } from 'react-router-dom';
import AsyncComponent from './components/AsyncComponent';
import { GOOGLE_ANALYTICS } from './config';

const AsyncHome = AsyncComponent(() => import('./pages/Home'));
const AsyncBlog = AsyncComponent(() => import('./pages/Blog'));
const AsyncWork = AsyncComponent(() => import('./pages/Work'));
const AsyncProjects = AsyncComponent(() => import('./pages/Projects'));
const AsyncProjectDetails = AsyncComponent(() => import('./pages/ProjectDetails'));
const AsyncResume = AsyncComponent(() => import('./pages/Resume'));
const AsyncContact = AsyncComponent(() => import('./pages/Contact'));
const AsyncNotFound = AsyncComponent(() => import('./components/NotFound'));

ReactGA.initialize(GOOGLE_ANALYTICS.universalTrackingId);

const withTracker = (
  Component: React.ComponentType<any>,
  options: any = {}
) => {
  const trackPage = (page: string) => {
    ReactGA.set({
      page,
      ...options
    });
    ReactGA.pageview(page);
  };

  interface WithTrackerProps {
    location: any;
  }
  return class WithTracker extends React.Component<WithTrackerProps> {
    componentDidMount() {
      const page = this.props.location.pathname;
      trackPage(page);
    }

    componentWillReceiveProps(nextProps: any) {
      const currentPage = this.props.location.pathname;
      const nextPage = nextProps.location.pathname;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <Component {...this.props} />;
    }
  };
};

const withNavbar = (
  Component: React.ComponentType<any>,
  options: AppShellProps
) =>
  class WithLoading extends React.Component {
    render() {
      return (
        <AppShell {...options}>
          <Component {...this.props} />
        </AppShell>
      );
    }
  };

class App extends Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path='/'
          component={withTracker(
            withNavbar(AsyncHome, {
              navbarTitle: 'Home'
            })
          )}
        />
        <Route
          exact
          path='/blog'
          component={withTracker(
            withNavbar(AsyncBlog, { navbarTitle: 'Sidhant Panda / Blogs' })
          )}
        />
        <Route
          exact
          path='/work'
          component={withTracker(
            withNavbar(AsyncWork, { navbarTitle: 'Sidhant Panda / Work' })
          )}
        />
        <Route
          exact
          path='/projects'
          component={withTracker(
            withNavbar(AsyncProjects, {
              navbarTitle: 'Sidhant Panda / Projects'
            })
          )}
        />
        <Route
          exact
          path='/projects/:projectId'
          component={withTracker(
            withNavbar(AsyncProjectDetails, {
              navbarTitle: 'Sidhant Panda / Projects'
            })
          )}
        />
        <Route
          exact
          path='/resume'
          component={withTracker(
            withNavbar(AsyncResume, {
              navbarTitle: 'Sidhant Panda / Resume'
            })
          )}
        />
        <Route
          exact
          path='/contact'
          component={withTracker(
            withNavbar(AsyncContact, {
              navbarTitle: 'Sidhant Panda / Contact'
            })
          )}
        />
        <Route
          component={withTracker(withNavbar(AsyncNotFound, { navbarTitle: '404' }))}
        />
      </Switch>
    );
  }
}

export default App;
