import React, { Component } from 'react';
import { Menu, Segment, Sidebar, Container, Icon } from 'semantic-ui-react';
import { NavLink } from "react-router-dom";

const Nav = (props: any) => (
  <NavLink
    exact
    {...props}
    activeClassName="active"
  />
);

interface MobileSideNavbarProps {
  navbarTitle?: string
}
class MobileSideNavbar extends Component<MobileSideNavbarProps> {
  state = { sidebarOpened: false }

  handleSidebarHide = () => {
    this.setState({ sidebarOpened: false })
  }


  handleToggle = () => {
    const state = this.state.sidebarOpened;
    this.setState({ sidebarOpened: !state })
  }

  componentDidMount = () => {

  }

  render() {
    const { children } = this.props;
    const { sidebarOpened } = this.state;

    return (
      <div>
        <Sidebar
          as={Menu}
          animation='push'
          inverted
          // onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened} >
          <Menu.Item as={Nav} to='/'
            name='home'
            onClick={this.handleSidebarHide} />
          <Menu.Item as={Nav} to='/blog'
            name='blog'
            onClick={this.handleSidebarHide} />
          {/* <Menu.Item as={Nav} to='/work'
            name='work'
            onClick={this.handleSidebarHide} /> */}
          <Menu.Item as={Nav} to='/projects'
            name='projects'
            onClick={this.handleSidebarHide} />
          <Menu.Item as={Nav} to='/resume'
            name='resume'
            onClick={this.handleSidebarHide} />
          {/* <Menu.Item as={Nav} to='/about'
            name='about'
            onClick={this.handleSidebarHide} /> */}
          <Menu.Item as={Nav} to='/contact'
            name='contact'
            onClick={this.handleSidebarHide} />
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened} >
          <Segment
            inverted
            textAlign='center'
            style={{ padding: '0em 0em' }}
            vertical>
            <Container>
              <Menu inverted pointing secondary size='large'>
                <Menu.Item onClick={this.handleToggle} >
                  <Icon name={sidebarOpened ? 'close' : 'sidebar'} />
                  {this.props.navbarTitle &&
                    this.props.navbarTitle
                  }
                </Menu.Item>
              </Menu>
            </Container>
          </Segment>

          <div onClick={this.handleSidebarHide}>

            {children}
          </div>
        </Sidebar.Pusher>
      </div>
    );
  }
}

export default MobileSideNavbar;
